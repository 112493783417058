<template>
  <div class="card-body">
    <div class="d-flex mb-1">
      <div class="text-uppercase font-weight-bold mr-auto">
        Revenue
      </div>
      <div>
        +20% Goal Reached
      </div>
    </div>
    <div class="d-flex mb-2">
      <div class="font-size-24 font-weight-bold mr-auto">+3,125</div>
      <div class="font-size-24">5,000</div>
    </div>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        style="width: 60%"
        role="progressbar"
        aria-valuenow="60"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral12v1',
}
</script>
